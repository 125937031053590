/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import {
  Flags_ar,
  Flags_es,
  Flags_fr,
  Flags_nl,
  Flags_us,
  Logo_img,
} from "../imagepath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChevronDown,
  faGear,
  faReceipt,
  faTimes,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { default as logo } from "../../assets/img/Mokoto.png";
import TokenManager from "../../../Apis/TokenManager";
// eslint-disable-next-line no-unused-vars
const HeaderTwo = (props) => {
  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenu1, setSideMenu1] = useState("");
  const [isSideMenu2, setSideMenu2] = useState("");

  const claims = TokenManager.getClaims();

  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const toggleSidebar1 = (value) => {
    setSideMenu1(value);
  };
  const toggleSidebar2 = (value) => {
    setSideMenu2(value);
  };

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  let pathname = window.location.pathname;

  //nav transparent

  // eslint-disable-next-line no-unused-vars
  const [click, setClick] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const Reload2 = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* Header */}
      <header className={`header header-two ${navbar ? "sticky" : ""} `}>
        <div className="top-header">
          <div className="container">
            <div className="top-head-items">
              <ul className="nav user-menu">
                {/* Flag */}
                <li className="nav-item dropdown has-arrow flag-nav">
                  <Link
                    className="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    to="#"
                    role="button"
                  >
                    <img
                      src={Flags_us}
                      className="me-1"
                      alt="Flag"
                      height={20}
                    />{" "}
                    <span>English</span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_us} alt="Flag" height={16} /> English
                    </Link>
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_nl} alt="Flag" height={16} /> Dutch
                    </Link>
                    <Link to="#" className="dropdown-item">
                      <img src={Flags_ar} alt="Flag" height={16} /> Arabic
                    </Link>
                  </div>
                </li>
                {/* /Flag */}
              </ul>
              <ul className="top-header-links">
                {/* <li>
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin-in" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-regular fa-bell" />
                      </Link>
                    </li>
                  </ul>
                </li> */}
                {!claims && (
                  <>
                    <li>
                      <Link to="/register">
                        <i className="feather feather-lock" />
                        Register
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">
                        <i className="feather feather-user" />
                        Login
                      </Link>
                    </li>
                  </>
                )}
                {/* <li>
                  <Link to="/admin/login" target="_blank">
                    <i className="feather feather-user" />
                    Admin
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg header-nav p-0">
            <div className="navbar-header header-select">
              <Link id="mobile_btn" to="#" onClick={() => onHandleMobileMenu()}>
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to="/index" className="navbar-brand logo">
                <img src={logo} width={100} className="img-fluid" alt="Logo" />
              </Link>
              {/* <div className="input-group me-5">
                <Select
                  className="ms-4"
                  options={options}
                  placeholder="Categories"
                />
              </div> */}
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                    width={100}
                  />
                </Link>
                <Link
                  id="menu_close"
                  className="menu-close"
                  to="#"
                  onClick={() => onhandleCloseMenu()}
                >
                  <i>
                    <FontAwesomeIcon icon={faTimes} />
                  </i>
                </Link>
              </div>
              <ul className="main-nav main-two">
                <li
                  className={`has-submenu ${pathname === "/" ? "active" : ""}`}
                >
                  <Link
                    to="/"
                    className={isSideMenu === "home" ? "subdrop" : ""}
                  >
                    Home
                  </Link>
                </li>
                <li
                  className={` has-submenu ${
                    pathname === "/about" ? "active" : ""
                  }`}
                >
                  <Link to="/about">About</Link>
                </li>
                <li
                  className={` has-submenu ${
                    pathname === "/contact" ? "active" : ""
                  }`}
                >
                  <Link to="/contact">Contact</Link>
                </li>

                {claims && (
                  <li
                    className={`has-submenu ${
                      pathname === "developer" ||
                      pathname === "developer-details" ||
                      pathname === "dashboard" ||
                      pathname === "manage-projects" ||
                      pathname === "favourites" ||
                      pathname === "invited-freelancer" ||
                      pathname === "membership-plans" ||
                      pathname === "milestones" ||
                      pathname === "chats" ||
                      pathname === "review" ||
                      pathname === "deposit-funds" ||
                      pathname === "withdraw-money" ||
                      pathname === "transaction-history" ||
                      pathname === "verify-identity" ||
                      pathname === "profile-settings" ||
                      pathname === "tasks" ||
                      pathname === "files" ||
                      pathname === "view-project-detail" ||
                      pathname === "project-payment"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className={isSideMenu === "foremployers" ? "subdrop" : ""}
                      onClick={() =>
                        toggleSidebar(
                          isSideMenu === "foremployers" ? "" : "foremployers"
                        )
                      }
                      onMouseEnter={() => setSideMenu("foremployers")}
                    >
                      <FontAwesomeIcon icon={faUser} /> Profile
                      <i>{/* <FontAwesomeIcon icon={faChevronDown} /> */}</i>
                    </Link>
                    {isSideMenu === "foremployers" ? (
                      <ul className="submenu">
                        <li
                          className={
                            pathname === "account-details" ? "active" : ""
                          }
                        >
                          <Link to="/account-details">
                            <FontAwesomeIcon icon={faAddressCard} />
                            My Details
                          </Link>
                        </li>
                        <li
                          className={
                            pathname === "membership-plans" ? "active" : ""
                          }
                        >
                          <Link to="/membership">
                            <FontAwesomeIcon icon={faReceipt} /> Membership
                          </Link>
                        </li>
                        <li className={pathname === "settings" ? "active" : ""}>
                          <Link to="/settings">
                            <FontAwesomeIcon icon={faGear} /> Settings
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                )}
              </ul>
            </div>

            <ul className="nav header-navbar-rht">
              {claims && (
                <li>
                  <ul className="nav header-navbar-rht">
                    <li>
                      <Link to="/post-project" className="login-btn">
                        <i className="fa-solid fa-plus me-2"></i>Share Post{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/request-part" className="login-btn">
                        <i className="fa-solid fa-plus me-2"></i>Request Part{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </header>
      {/* /Header */}
    </>
  );
};

export default HeaderTwo;
