/* eslint-disable no-unused-vars */
import React from "react";
import AppContainer from "./appcontainer.jsx";
import { BrowserRouter as Router, Route } from "react-router-dom";
// notifications
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
// import config from "config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CategoryProvider } from "../frontend/components/Category.js";
import { SubCategoryProvider } from "../frontend/components/SubCategoryContext.js";
const AppRouter = (props) => {
  return (
    <>
      {/* <Router basename={`${config.publicPath}`}> */}
      <CategoryProvider>
        <SubCategoryProvider>
          <Router basename="/">
            {/* <Router> */}
            <Route render={(props) => <AppContainer {...props} />} />
          </Router>
          <NotificationContainer />
          <ToastContainer
            position="bottom-right" // Position the toast container at the bottom-right
            autoClose={5000} // Auto close after 5 seconds
          />
        </SubCategoryProvider>
      </CategoryProvider>
    </>
  );
};

export default AppRouter;
