/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  logo_icon_01,
  Avatar_1,
  Avatar_2,
  Avatar_3,
  Avatar_4,
  Avatar_5,
  default_logo,
  computer_line,
  time_line,
  user_heart_line,
  translate_2,
  translate,
  Project_21,
} from "../../imagepath";

import StickyBox from "react-sticky-box";
import EmployerBreadcrumb from "../../foremployers/common/employerBreadcrumb";
import TextEditor from "../../foremployers/dashboard/texteditor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useParams } from "react-router-dom";
import TechOffers from "../../../../Apis/TechOffersApi";
import { useCategory } from "../../Category";
import AuthApi from "../../../../Apis/AuthApi";
import getDateTime from "../../../../getDateTime";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import TokenManager from "../../../../Apis/TokenManager";
import LoadingSpinner from "../../../../LoadingSpinner";
import { toast } from "react-toastify";

const CompanyProfile = () => {
  const history = useHistory();
  let params = useParams();
  const claims = TokenManager.getClaims();
  const { categories, getCategoryById } = useCategory();
  const [post, setPost] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const [comment, setComment] = useState();
  const [userCommentCache, setUserCommentCache] = useState({});

  const getPostById = async (id) => {
    setLoading(true);
    const response = await TechOffers.getTechOffer(id);
    if (response.success) {
      setPost(response.data);
      await fetchUsers(response.data?.comments);
      const requestUser = await getUserById(response.data?.userId);
      setUser(requestUser);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const fetchUsers = async (requests) => {
    const uniqueUserIds = [...new Set(requests.map((post) => post.userId))];
    const userPromises = uniqueUserIds.map((id) => getUserById(id));
    const users = await Promise.all(userPromises);

    const userCacheData = users.reduce((acc, user) => {
      if (user) {
        acc[user.id] = user;
      }
      return acc;
    }, {});
    setUserCommentCache(userCacheData);
  };

  const getUserById = async (id) => {
    if (userCommentCache[id]) {
      return userCommentCache[id];
    }

    const response = await AuthApi.getUser(id);
    if (response.success) {
      return response.data;
    } else {
      toast.error("something went wrong");
      return null;
    }
  };

  const handleAddComment = async () => {
    if (!comment || comment === "") {
      toast.error("you can not send empty message");
      return;
    }
    const payload = {
      techOfferId: post.id,
      text: comment,
    };
    const response = await TechOffers.addTechOfferComment(payload);
    if (response.success) {
      getPostById(params.id);
      setComment("");
      toast.success("your comment is added successfullt");
    }
  };
  const handleDeleteComment = async (id) => {
    const payload = {
      id: id,
      techOfferId: post.id,
    };
    const response = await TechOffers.removeTechOfferComment(payload);
    if (response.success) {
      getPostById(params.id);
      setComment("");
      toast.success("your comment is deleted successfullt");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const isValidUUID = (id) => {
        const uuidRegex =
          /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(id);
      };
      if (params.id && isValidUUID(params.id)) {
        getPostById(params.id);
      } else {
        history.push("/404");
      }
    };
    fetchData();
  }, [params?.id]);

  if (!params.id) {
    history.pushState("/404");
  }
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      {/* Breadcrumb */}
      <EmployerBreadcrumb title="Part Details" subtitle="Project Details" />
      {/* /Breadcrumb */}

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="company-detail-block pt-0">
                <div className="company-detail">
                  <div className="company-detail-image">
                    <img
                      src={user?.image || Avatar_1}
                      className="img-fluid"
                      alt="logo"
                      style={{ width: "150px", height: "150px" }}
                    />
                  </div>
                  <div className="company-title">
                    <p>{user?.businessName}</p>
                    <h4>{post?.title}</h4>
                  </div>
                </div>
                <div className="company-address">
                  <ul>
                    <li>
                      <i className="feather-map-pin" />
                      {user?.address?.city},{user?.address?.country}
                    </li>
                    <li>
                      <i className="feather-calendar" />
                      {getDateTime.getDate(post?.createdDate)}
                    </li>
                    {/* <li>
                      <i className="feather-eye" />
                      902 Views
                    </li> */}
                    <li>
                      <i className="feather-edit-2" />
                      {post?.comments?.length} Proposal
                    </li>
                  </ul>
                </div>
                <div className="tags">
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      {getCategoryById(post?.categoryId)}
                    </span>
                  </Link>
                </div>
                {/* <div className="project-proposal-detail">
                  <ul>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={computer_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Freelancer Type</span>
                        <p className="mb-0">Full Time</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={time_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Project Type</span>
                        <p className="mb-0">Hourly</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={time_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Project Duration</span>
                        <p className="mb-0">10-15 Hours</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={user_heart_line} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Experience</span>
                        <p className="mb-0">Basic</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={translate_2} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Languages</span>
                        <p className="mb-0">English, Arabic</p>
                      </div>
                    </li>
                    <li>
                      <div className="proposal-detail-img">
                        <img src={translate} alt="icons" />
                      </div>
                      <div className="proposal-detail text-capitalize">
                        <span className=" d-block">Language Fluency</span>
                        <p className="mb-0">Conversational</p>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="company-detail-block company-description">
                <h4 className="company-detail-title">Description</h4>
                <p
                  style={{
                    maxWidth: "825",
                    overflowWrap: "break-word",
                    wordBreak: "break-word",
                  }}
                >
                  {post?.description}
                </p>
              </div>
              {/* <div className="company-detail-block company-description">
                <h4 className="company-detail-title">Skills Required</h4>
                <div className="tags">
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      After Effects
                    </span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      Illustrator
                    </span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">HTML</span>
                  </Link>
                  <Link to="#">
                    <span className="badge badge-pill badge-design">
                      Whiteboard
                    </span>
                  </Link>
                </div>
              </div> */}
              <div className="company-detail-block">
                <h4 className="company-detail-title">Images</h4>
                <div className="row row-gap">
                  <Gallery>
                    {post?.images?.map((item, index) => (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="1024"
                        height="768"
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={item}
                            alt={`Image ${index + 1}`}
                            style={{
                              cursor: "pointer",
                              width: 200,
                              height: 200,
                            }}
                          />
                        )}
                      </Item>
                    ))}
                  </Gallery>

                  {/* <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>sample.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                      <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Website logo.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Banner image.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">

                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Services image.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">

                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>About us.jpg</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="attachment-file">
                    <img src={Project_21} alt="" width={50}/>
                      <div className="attachment-files-details">
                        <h6>Website Content.png</h6>
                        <span>file size 139 KB</span>
                      </div>
                      <Link to="#" className="file-download-btn">
                        <i className="feather-trash" />
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="company-detail-block pb-0">
                <h4 className="company-detail-title">
                  Comments ({post?.comments?.length})
                </h4>
                {claims.sub !== user?.id && (
                  <>
                    <h5>Write Comment</h5>
                    <p>your comment will be visible for premium users only.</p>
                    <textarea
                      rows={5}
                      className="form-control mb-3"
                      placeholder="Start typing your comment..."
                      value={comment}
                      onChange={(e) => setComment(e.currentTarget.value)}
                    />
                    <button
                      type="button"
                      className="btn  btn-primary mb-3"
                      style={{ width: "100%", padding: "10px" }}
                      onClick={handleAddComment}
                    >
                      Submit Proposal
                    </button>
                  </>
                )}

                {post?.comments?.map((comment, ind) => {
                  const userComment = userCommentCache[comment.userId] || {};

                  return (
                    <>
                      <div
                        className="project-proposals-block "
                        key={comment.id}
                      >
                        <div className="project-proposals-img">
                        <Link to={`/company-details/${userComment?.id}`}>
                          <img
                            src={userComment?.image || Avatar_1}
                            className="img-fluid"
                            alt="user"
                          />
                          </Link>
                        </div>
                        <div className="project-proposals-description">
                          <div className="proposals-user-detail">
                            <div>
                              <h5><Link to={`/company-details/${userComment?.id}`}>{userComment?.businessName}</Link></h5>
                              <ul className="d-flex">
                                {/* <li>
            <div className="proposals-user-review">
              <span>
                <i className="fa fa-star" />
                5.0 (346 Reviews)
              </span>
            </div>
          </li> */}
                                <li>
                                  <div className="proposals-user-review">
                                    <span>
                                      <i className="feather-calendar" />
                                      {getDateTime.getDate(
                                        comment.createdAt
                                      )} |{" "}
                                      {getDateTime.getTime(comment.createdAt)}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <div className="proposals-pricing">
                                {/* <h4>$40-$500</h4> */}
                                {/* <span>Price : Fixed </span> */}
                              </div>
                            </div>
                          </div>
                          <p
                            className="mb-0"
                            style={{ width: "620px", wordBreak: "break-all" }}
                          >
                            {comment?.text}
                          </p>
                        </div>
                        {comment.userId=== claims.sub && (
                          <a href="#" style={{margin:"0 auto"}} onClick={()=>handleDeleteComment(comment.id)}>
                            <i className="fas fa-trash"></i>
                          </a>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {/* Blog Sidebar */}
            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar project-client-view">
              <StickyBox offsetTop={70} offsetBottom={20}>
                {/* <div className="card budget-widget">
                  <div className="budget-widget-details">
                    <h6>Budget</h6>
                    <h4>$125 - $180</h4>
                    <p className="mb-0">Hourly Rate</p>
                  </div>
                  <p>Write comment and let the owner contact you.</p>
                  <div>
                    <Link
                      data-bs-toggle="modal"
                      to="#file"
                      className="btn proposal-btn btn-primary"
                    >
                      Submit Proposal
                    </Link>
                  </div>
                </div> */}
                {claims.sub !== user?.id && (
                  <div className="card budget-widget">
                    <div className="budget-widget-details">
                      <h6>About Client</h6>
                      <div className="company-detail-image">
                        <img
                          src={user?.image || Avatar_1}
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <h5>{user?.businessName}</h5>
                      {/* <span>Member Since Juli 31, 2024</span> */}
                      {/* <div className="rating mb-3">
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <i className="fas fa-star filled" />
                                    <span className="average-rating">5.0</span>
                                  </div> */}
                      {/* 
                                  <ul className="d-flex list-style mb-0 social-list">
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-facebook-f" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fab fa-twitter" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-linkedin-in" />
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="#" className="social-link-block">
                                        <i className="fa-brands fa-instagram" />
                                      </Link>
                                    </li>
                                  </ul> */}

                      {/* <ul className="d-flex list-style mb-0 client-detail-list">
                                    <li>
                                      <span>Departments</span>
                                      <p className="mb-0">Designer</p>
                                    </li>
                                    <li>
                                      <span>Employees</span>
                                      <p className="mb-0">30-50</p>
                                    </li>
                                  </ul> */}
                    </div>
                    <div>
                      <Link
                        to={`/company-details/${user?.id}`}
                        className="btn  btn-primary price-btn btn-block"
                      >
                        Contact Me{" "}
                      </Link>
                    </div>
                  </div>
                )}

                {/* <div className="card budget-widget">
                  <ul className="d-flex mb-0 list-style job-list-block">
                    <li>
                      <span>Posts</span>
                      <p className="mb-0">48</p>
                    </li>
                    <li>
                      <span>Requests</span>
                      <p className="mb-0">22</p>
                    </li>
                  
                  </ul>
                </div> */}
              </StickyBox>
            </div>
            {/* /Blog Sidebar */}
          </div>
        </div>
      </div>

      {/* The Modal */}

      <div className="modal fade" id="file">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h4 className="modal-title">Send Your Proposal</h4>
              <span className="modal-close">
                <Link to="#" data-bs-dismiss="modal" aria-label="Close">
                  <i className="fa fa-times orange-text" />
                </Link>
              </span>
            </div>
            <div className="modal-body">
              <div className="modal-info proposal-modal-info">
                <form action="/freelancer-project-proposals">
                  <div className="feedback-form proposal-form ">
                    <div className="row">
                      {/* <div className="col-md-6 input-block">
                        <label className="form-label">Your Price</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Price"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Estimated Delivery</label>
                        <div className="input-group ">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Estimated Hours"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            Days
                          </span>
                        </div>
                      </div> */}
                      <div className="col-md-12 input-block">
                        <label className="form-label">Write Comment</label>
                        <TextEditor />
                      </div>
                    </div>
                  </div>
                  {/* <div className="suggested-milestones-form">
                    {rows.map((row, index) => (
                      <div className="row" key={row.id}>
                        <div className="col-md-4 input-block">
                          <label className="form-label">Milestone name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Milestone name"
                          />
                        </div>
                        <div className="col-md-2 input-block floating-icon">
                          <label className="form-label">Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                          />
                          <span>
                            <i className="feather-dollar-sign" />
                          </span>
                        </div>
                        <div className="col-md-3 input-block floating-icon">
                          <label className="form-label">Start Date</label>
                          <DatePicker
                            selected={date}
                            onChange={handleChange}
                            className="form-control datetimepicker"
                          />
                          <span>
                            <i className="feather-calendar" />
                          </span>
                        </div>
                        <div className="col-md-3 input-block floating-icon">
                          <label className="form-label">End Date</label>
                          <DatePicker
                            selected={date}
                            onChange={handleChange}
                            className="form-control datetimepicker"
                          />
                          <span>
                            <i className="feather-calendar" />
                          </span>
                        </div>
                        <div className="col-md-12">
                          <div className="new-addd">
                            <Link
                              to="#"
                              className={`add-new ${
                                index > 0 ? "d-none" : "d-block"
                              }`}
                              onClick={addNewRow}
                            >
                              <i className="feather-plus-circle " /> Add New
                            </Link>
                            <Link
                              to="#"
                              className={`add-new ${
                                index > 0 ? "d-block" : "d-none"
                              }`}
                              onClick={() => deleteRow(row.id)}
                            >
                              <i className="feather-minus-circle " /> Remove
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div id="add_row1" />
                  </div> */}
                  {/* <div className="proposal-features">
                    <div className="proposal-widget proposal-warning">
                      <label className="custom_check">
                        <input
                          type="checkbox"
                          name="select_time"
                          defaultChecked
                        />
                        <span className="checkmark" />
                        <span className="proposal-text">
                          Stick this Proposal to the Top
                        </span>
                        <span className="proposal-text float-end">$12.00</span>
                      </label>
                      <p>
                        The sticky proposal will always be displayed on top of
                        all the proposals.
                      </p>
                    </div>
                    <div className="proposal-widget proposal-blue">
                      <label className="custom_check">
                        <input type="checkbox" name="select_time" />
                        <span className="checkmark" />
                        <span className="proposal-text">
                          Stick this Proposal to the Top
                        </span>
                        <span className="proposal-text float-end">$12.00</span>
                      </label>
                      <p>
                        The sticky proposal will always be displayed on top of
                        all the proposals.
                      </p>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-12 submit-section">
                      <label className="custom_check">
                        <input type="checkbox" name="select_time" />
                        <span className="checkmark" /> I agree to the Terms And
                        Conditions
                      </label>
                    </div>
                    <div className="col-md-12 submit-section text-end">
                      <Link
                        data-bs-dismiss="modal"
                        className="btn btn-cancel submit-btn"
                      >
                        Cancel
                      </Link>
                      <Link
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        to="#success"
                        className="btn btn-primary submit-btn"
                      >
                        Send Proposal
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /The Modal */}
    </>
  );
};
export default CompanyProfile;
