import axios from "axios"
import TokenManager from "./TokenManager"

const SWAGGER_BASE_URL = "https://app-bunduk-techforshareapi-dev.azurewebsites.net/Users"

const AuthApi ={
    login: async (payload) => {
        try {
          const response = await axios.post(SWAGGER_BASE_URL + "/login", payload)
          const token = response.data.data.access_Token
          TokenManager.setAccessToken(token)
          return { success: true, data: response.data.data } 
        } catch (error) {
          return { success: false, error: error };
        }
      },
      register:async (payload) =>{
        try{
            const response = await axios.post(SWAGGER_BASE_URL +"/register",payload,)
            const token = response.data.data.access_Token
            TokenManager.setAccessToken(token)
            const claims = TokenManager.getClaims()
            return { success: true, data: { token, claims } }
        } catch(error){
            return { success: false, error: error };
        }
      },
      approveUser:async (id) =>{
        try{
            const response = await axios.post(SWAGGER_BASE_URL +"/approveuser",id,
              {
                headers: {
                  Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                },
              }
            )
            return { success: true, data: response.data}
        } catch(error){
            return { success: false, error: error };
        }
      },
      getUser:async (id) =>{
        try{
            const response = await axios.get(SWAGGER_BASE_URL +"/"+id,
              {
                headers: {
                  Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                },
              }
            )
            return { success: true, data: response.data.data}
        } catch(error){
            return { success: false, error: error };
        }
      },
      updateUser:async (payload) =>{
        try{
            const response = await axios.put(SWAGGER_BASE_URL ,payload,
              {
                headers: {
                  Authorization: `Bearer ${TokenManager.getAccessToken()}`,
                },
              }
            )
            return { success: true, data: response.data.data}
        } catch(error){
            return { success: false, error: error };
        }
      },
}
export default AuthApi