import axios from "axios";
import TokenManager from "./TokenManager";

const SWAGGER_CATEGORIES_URL =
  "https://app-bunduk-techforshareapi-dev.azurewebsites.net/Categories";

const CategoriesApi = {
  getAllCategories: async () => {
    try {
      const response = await axios.get(SWAGGER_CATEGORIES_URL, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  getCategoryById: async (id) => {
    try {
      const response = await axios.get(SWAGGER_CATEGORIES_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  addCategory: async (payload) => {
    try {
      const response = await axios.post(SWAGGER_CATEGORIES_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  updateCategory: async (payload) => {
    try {
      const response = await axios.patch(SWAGGER_CATEGORIES_URL,payload, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
  deleteCategory: async (id) => {
    try {
      const response = await axios.delete(SWAGGER_CATEGORIES_URL+"/"+id, {
        headers: {
          Authorization: `Bearer ${TokenManager.getAccessToken()}`,
        },
      });
      return { success: true, data: response.data.data };
    } catch (error) {
      return { success: false, error: error };
    }
  },
};
export default CategoriesApi;
