/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import { Img_04 } from "../../imagepath";
const ProfileSidebar = ({ user }) => {
  const pathname = window.location.pathname.split("/")[1];
  const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=${encodeURIComponent(
    `${user?.address?.street}, ${user?.address?.city}, ${user?.address?.state}, ${user?.address?.postCode}, ${user?.address?.country}`
  )}`;
  return (
    <>
      {/* profile Sidebar */}

      {/* About Widget */}
      <div className="pro-post widget-box about-widget profile-overview">
        <div className="profile-head">
          <h4 className="pro-title mb-0">Profile Overview</h4>
        </div>
        <ul className="latest-posts pro-content">
          <li>
            <p>Shop Name</p>
            <h6>{user?.businessName}</h6>
          </li>
          {/* <li>
                                        <p>Joined</p>
                                        <h6>02-06-2024</h6>
                                    </li> */}
          <li>
            <p>Email</p>
            <h6>{user?.email}</h6>
          </li>
          <li>
            <p>Website</p>
            <h6>www.techforshare.com</h6>
          </li>
        </ul>
        <div className="contact-btn">
          <a href={`tel:${user?.phoneNumber}`} className="btn btn-primary">
            <i className="fas fa-phone-alt" /> Contact
          </a>
        </div>
      </div>
      {/* /About Widget */}
      {/* Company Location */}
      <div className="pro-post widget-box location-widget">
        <div className="profile-head">
          <h4 className="pro-title">Company Location</h4>
        </div>
        <div className="map-location">
        <iframe
            src={googleMapsUrl}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      {/* /Company Location */}
      {/* Working Widget */}
      {/* <div className="pro-post widget-box  working-days">
                                <div className="profile-head">
                                    <h4 className="pro-title mb-0">Working Days</h4>
                                </div>
                                <ul className="latest-posts pro-content">
                                    <li>
                                        <p>Monday</p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Tuesday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Wednesday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Thursday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Friday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Saturday </p>
                                        <h6>9AM - 5PM</h6>
                                    </li>
                                    <li>
                                        <p>Sunday </p>
                                        <h6>
                                            <span>Close</span>
                                        </h6>
                                    </li>
                                </ul>
                            </div> */}
      {/* /Working Widget */}
      {/* Social Widget */}
      <div className="pro-post widget-box social-widget">
        <div className="profile-head">
          <h4 className="pro-title">SOCIAL LINKS</h4>
        </div>
        <ul className="social-link-profile">
          <li>
            <Link to="#">
              <i className="fab fa-facebook" />
            </Link>
          </li>
          {/* <li>
                                        <Link to="#">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                    </li> */}
          <li>
            <Link to="#">
              <i className="fab fa-instagram" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-linkedin" />
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="fab fa-telegram" />
            </Link>
          </li>
        </ul>
      </div>
      {/* /Social Widget */}
      {/* /Profile Sidebar */}
    </>
  );
};
export { ProfileSidebar };
