/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link,useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { FiArrowRight, FiArrowUp } from "react-icons/fi";
import { BiMap } from "react-icons/bi";
import {
  Project_19,
  logo_icon_01,
  Project_20,
  Project_21,
  Project_22,
  Project_23,
  Project_24,
  Project_25,
  Project_26,
  Project_27,
  Avatar_1,
} from "../../imagepath";
import EmployerBreadcrumb from "../../foremployers/common/employerBreadcrumb";
import FreelancerSidebar from "../../foremployers/common/freelancerSidebar";
import TechOffers from "../../../../Apis/TechOffersApi";
import CategoriesApi from "../../../../Apis/CategoriesApi";
import SubCategoryApi from "../../../../Apis/SubCategoryApi";
import getDateTime from "../../../../getDateTime";
import SkeletonPost from "../../SkeletonPost";
import { useCategory } from "../../Category";
import { toast } from "react-toastify";
import AuthApi from "../../../../Apis/AuthApi";
import TokenManager from "../../../../Apis/TokenManager";
 
const Project = () => {
  const location= useLocation()
  const keyword = location.state?.key || "";
  const claims = TokenManager.getClaims();
  const [allposts, setAllPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [user, setUser] = useState();
  const { categories, getCategoryById } = useCategory();
  const [loading, setLoading] = useState(false);
  const [userCache, setUserCache] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  useEffect(() => { 
    console.log(keyword)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, []);

  const getAllPosts = async () => {
    setLoading(true);
    const response = await TechOffers.getAllTechOffers();
    if (response.success) {
      const posts = response.data;
      setAllPosts(posts);
      setTotalPages(Math.ceil(posts.length / postsPerPage));
      updateFilteredPosts(posts, currentPage);
      await fetchUsers(posts);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  const updateFilteredPosts = (posts, page) => {
    const startIndex = (page - 1) * postsPerPage;
    const endIndex = startIndex + postsPerPage;
    setFilteredPosts(posts.slice(startIndex, endIndex));
  };

  const fetchUsers = async (posts) => {
    const uniqueUserIds = [...new Set(posts.map((post) => post.userId))];
    const userPromises = uniqueUserIds.map((id) => getUserById(id));
    const users = await Promise.all(userPromises);

    const userCacheData = users.reduce((acc, user) => {
      if (user) {
        acc[user.id] = user;
      }
      return acc;
    }, {});

    setUserCache(userCacheData);
  };

  const getUserById = async (id) => {
    if (userCache[id]) {
      return userCache[id];
    }

    const response = await AuthApi.getUser(id);
    if (response.success) {
      return response.data;
    } else {
      toast.error("something went wrong");
      return null;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const fetchedUser = await getUserById(claims.sub);
        setUser(fetchedUser);
        if (fetchedUser?.status === "Approved") {
          await getAllPosts();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Something went wrong");
      }
    };
  
    fetchUserData();
  }, [claims.sub]);  

  useEffect(() => {
    if (allposts.length > 0) {
      setFilteredPosts(allposts);
    }
  }, [allposts]);

  useEffect(() => {
    if (allposts.length > 0) {
      updateFilteredPosts(allposts, currentPage);
    }
  }, [currentPage, allposts]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  };



  return (
    <>
      {/* Breadcrumb */}
      <EmployerBreadcrumb title="Browse Posts" subtitle="Posts" />
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                {/* Search Filter */}
                <FreelancerSidebar
              stateKeyword={keyword}
                  allposts={allposts}
                  setAllPosts={setFilteredPosts}
                />
                {/* /Search Filter */}
              </StickyBox>
            </div>
            {user && user?.status !== "Approved" ? (
              <div className="col-md-12 col-lg-8 col-xl-9">
                <p>your account is not approved yet</p>
              </div>
            ) : (
              <>
                <div className="col-md-12 col-lg-8 col-xl-9">
                  <div className="sort-tab develop-list-select">
                    <div className="row align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="d-flex align-items-center">
                          <div className="freelance-view">
                            {!loading && (
                              <h4>Found {filteredPosts?.length} Results</h4>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-end">
                        <ul className="list-grid d-flex align-items-center">
                          <li>
                            <Link to="/posts" className="favour-active">
                              <span>POST</span>
                            </Link>
                          </li>
                          <li>
                            <Link to="/requests">
                              <span className="favour">REQUESTS</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {loading
                      ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ind) => (
                          <SkeletonPost key={ind} />
                        ))
                      : filteredPosts?.map((post) => {
                          const user = userCache[post.userId] || {};
                          return (
                            <div
                              className="col-lg-4 col-md-6 col-sm-6 d-flex"
                              key={post.id}
                            >
                              <div className="project-item p-project-item">
                                <div className="project-img">
                                  <Link to={`post-details/${post.id}`}>
                                    <img
                                      src={post.images[0]}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </Link>
                                </div>
                                <div className="feature-content p-3">
                                  <div className="feature-time-blk">
                                    <Link
                                      to="#"
                                      className="btn btn-primary green-active"
                                      style={{ color: "white" }}
                                    >
                                      {getCategoryById(post?.categoryId)}
                                    </Link>
                                    <span>
                                      <i className="far fa-clock me-1" />
                                      {getDateTime.getDate(
                                        post.createdDate
                                      )}{" "}
                                      <br />
                                      {getDateTime.getTime(post.createdDate)}
                                    </span>
                                  </div>
                                  <h4>
                                    <Link to={`post-details/${post.id}`}>
                                      {post.title}
                                    </Link>{" "}
                                  </h4>
                                  <ul className="feature-project-list nav">
                                    <li>
                                      <BiMap className="feather-map-pin me-1" />
                                      {user.address?.city} /{" "}
                                      {user.address?.country}
                                    </li>
                                  </ul>
                                  <div className="feature-foot">
                                    <div className="logo-company">
                                      <Link to={`post-details/${post.id}`}>
                                        <img
                                          src={user.image || Avatar_1}
                                          width={50}
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        <span>{post.clientName}</span>
                                      </Link>
                                    </div>
                                    <Link
                                      to={`post-details/${post.id}`}
                                      className="bid-now"
                                    >
                                      Bid Now{" "}
                                      <FiArrowRight className="feather-arrow-right ms-1" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  {filteredPosts?.length > 10 && (
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="paginations list-pagination">
                          <li className="page-item">
                            <Link
                              to="#"
                              onClick={() => handlePageChange(currentPage - 1)}
                              className={currentPage === 1 ? "disabled" : ""}
                            >
                              <i className="feather-chevron-left" />
                            </Link>
                          </li>
                          {Array.from(
                            { length: totalPages },
                            (_, index) => index + 1
                          ).map((page) => (
                            <li key={page} className="page-item">
                              <Link
                                to="#"
                                className={page === currentPage ? "active" : ""}
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </Link>
                            </li>
                          ))}
                          <li className="page-item">
                            <Link
                              to="#"
                              onClick={() => handlePageChange(currentPage + 1)}
                              className={
                                currentPage === totalPages ? "disabled" : ""
                              }
                            >
                              <i className="feather-chevron-right" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* The Modal */}
      {/* <div className="modal fade " id="rating">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header d-block b-0 pb-0">
              <span className="modal-close float-end">
                <Link to="#" data-bs-dismiss="modal" aria-label="Close">
                  <i className="fa fa-times orange-text" />
                </Link>
              </span>
            </div>
            <div className="modal-body">
              <form action="posts">
                <div className="modal-info">
                  <div className="text-center pt-0 mb-5">
                    <h3>Please login</h3>
                  </div>
                  <div className="submit-section text-center">
                    <button
                      data-bs-dismiss="modal"
                      className="btn btn-primary black-btn click-btn"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary click-btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* /The Modal */}
    </>
  );
};

export default Project;
