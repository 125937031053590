/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { mail } from "../../imagepath";
const Completeing = ({ prevTab5 }) => {
  return (
    <>
      <div className="field-card">
        <div className="account-onborad complte-board back-home pb-0">
          <img src={mail} className="img-fluid" alt="icon" />
          <h2>Verification</h2>
          <h3>Your request has been received.</h3>
          <p>We are in the process of completing the verification.</p>
          <p> In the meantime, feel free to explore the website and enjoy your time</p>
          <Link to="posts" className="link-danger">
            <i className="feather-refresh-cw me-2 " /> Explore Posts
          </Link>
        </div>

        <div className="text-center">
          {/* <input
            className="btn btn-prev prev_btn btn-back"
            name="next"
            type="button"
            defaultValue="Back"
            onClick={prevTab5}
          /> */}
          <a href="/posts" className="btn btn-prev  btn-back">
            Done
          </a>
        </div>
      </div>
    </>
  );
};
export default Completeing;
